import axios from "axios";
export const sendmail = (name,email,contactNo,message) =>
{
	let data = {
	"name": name,
	"email": email,
	"contactNo": contactNo,
	"message": message
};

let config = {
  method: 'post',
  url: '/text-mail/',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});

		
}